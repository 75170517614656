import 'semantic-ui-css/semantic.min.css'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'
import App from './App';


window.dbListeners = {
  onUpdate: () => { }
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
)

declare global {
  interface Window {
    dbListeners: {
      onUpdate: () => void
    }
  }
}

