import React, { useState } from "react"
import { Button, Checkbox, Grid, Input, Modal } from "semantic-ui-react"
import { Taccount, updateAccountData } from "../../api"
import toast from "react-hot-toast"
import { AxiosError } from "axios"

const EditAccountData = ({ account, fetchAccounts }: { account: Taccount, fetchAccounts: Function }) => {
    const [opened, setOpened] = useState(false)
    const ratingHook = useState(account.rating)
    const confidenceHook = useState(account.confidence)
    const tokensHook = useState(account.tokens)
    const behaviorHook = useState(account.behavior)
    const trophyHook = useState(account.trophyLevel)

    const lpHook = useState(account.ammountOfLpOnAccount)
    const bannedHook = useState(account.status === 'banned')

    const [makingRequest, setMakingRequest] = useState(false)

    const wrapper: React.CSSProperties = {
        padding: '42px 18px'
    }

    function inputField<T>(stateHook: [T, React.Dispatch<React.SetStateAction<T>>], description: string) {
        return (
            <Grid.Column textAlign='center'>
                <div style={{ margin: '12px 0 12px 0', fontSize: '20px' }}>{description}</div>
                <Input fluid
                    disabled={makingRequest}
                    value={stateHook[0]}
                    onChange={e => stateHook[1](e.target.value as any)}
                />
            </Grid.Column>
        )
    }

    function checkboxField<T>(stateHook: [T, React.Dispatch<React.SetStateAction<T>>], description: string) {
        return (
            <Grid.Column textAlign='center'>
                <div style={{ margin: '12px 0 12px 0', fontSize: '20px' }}>{description}</div>
                <Checkbox
                    style={{ marginTop: '7px' }}
                    disabled={makingRequest}
                    checked={stateHook[0] as boolean}
                    onChange={(_, w) => stateHook[1](w.checked as any)}
                />
            </Grid.Column>
        )
    }
    function buttonField(text: string, onClick: () => void) {
        return (
            <Grid.Column textAlign='center'>
                <Button content={text} onClick={onClick} style={{
                    marginTop: '44px'
                } as React.CSSProperties} />
            </Grid.Column>
        )
    }

    return (
        <Modal open={opened} onOpen={_ => setOpened(true)} onClose={_ => setOpened(false)}
            trigger={<Button icon='pencil' circular color='grey' />}
            style={{ width: 'calc(60% + 300px)' }}
        >
            <div style={wrapper}>
                <Grid>
                    <Grid.Row columns={5}>
                        {inputField(ratingHook, 'rating')}
                        {inputField(confidenceHook, 'confidence')}
                        {inputField(tokensHook, 'tokens')}
                        {inputField(behaviorHook, 'behavior')}
                        {inputField(trophyHook, 'trophy')}
                    </Grid.Row>
                    <Grid.Row columns={5}>
                        {inputField(lpHook, 'lp')}
                        {checkboxField(bannedHook, 'banned')}
                        {buttonField('send data', () => {
                            setMakingRequest(true)

                            toast.promise(updateAccountData(
                                account._id, ratingHook[0], confidenceHook[0], tokensHook[0], behaviorHook[0], trophyHook[0], lpHook[0], bannedHook[0]
                            ), {
                                loading: 'Sending request...',
                                success: 'Updated!',
                                error: (error: AxiosError) => `Failed to update account data: ${error?.message}`
                            }).then(() => {
                                fetchAccounts()
                                setMakingRequest(false)
                            })

                        })}
                        <Grid.Column></Grid.Column>
                        <Grid.Column></Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </Modal>
    )
}

export default EditAccountData
