import { Button, Input } from "semantic-ui-react"
import { Taccount } from "../../api"
import * as api from '../../api'
import toast from "react-hot-toast"
import { useState } from "react"
import './newNoteInput.css';


type TProps = {
    account: Taccount
    updateAccount: Function
}

const NewNoteInput = ({ account, updateAccount }: TProps) => {

    const [newNoteText, setNewNoteText] = useState('')

    const newLineStyle: React.CSSProperties = {
        margin: '8px 8px',
    }

    const emptyNote: api.Tnote = {
        _id: '',
        text: 'Добавьте первую заметку',
        accountId: '',
        createdAt: '',
        updatedAt: ''
    }
    const lastNote = account.notes?.length === 0
        ? emptyNote
        : account.notes[account.notes.length - 1]

    const createNewNote = () => {
        api.addNote(newNoteText, account._id)
            .then(res => {
                console.log('created note for account', account._id, res)
                setNewNoteText('')
                updateAccount({ ...account, notes: [...account.notes, res] })
            })
            .catch(err => toast.error('Ошибка создания заметки:', err.message))
    }

    const tooltipText = lastNote.text + ' \n UpdatedAt: ' + new Date(lastNote.updatedAt).toLocaleString()
    const olderThanMonth = new Date(lastNote.updatedAt) < new Date(new Date().setMonth(new Date().getMonth() - 1))
    const placeholderText = olderThanMonth
        ? '(Old) ' + lastNote.text
        : lastNote.text
    return (
        <div style={newLineStyle} className="custom-input-wrapper">
            <Input
                className="custom-input"
                fluid
                placeholder={placeholderText}
                value={newNoteText}
                onChange={e => setNewNoteText(e.target.value)}
                action={<Button onClick={createNewNote} color="teal" content='+' />}
            />
            <span className="tooltip">{tooltipText}</span>
        </div>
    )
}

export default NewNoteInput
