import axios from "axios";
import React from "react";
import { toast } from "react-hot-toast";
import { Button, Input } from "semantic-ui-react";
import config from "../config";


const NewItem = () => {
    // component with fields: login, password, email, emailpassword.
    // send that to login, password, mail, mailPassword in put request to backend

    const [accountId, setAccountId] = React.useState("");
    const [name, setName] = React.useState("");
    const [src, setSrc] = React.useState("");
    const [rarity, setRarity] = React.useState("");
    const [type, setType] = React.useState("");
    const [side, setSide] = React.useState("");
    const [showsOnPopup, setShowsOnPopup] = React.useState("");
    const [hero, setHero] = React.useState("");

    const handleSubmit = () => {
        const data = {
            accountId, name, src, rarity, type, side, showsOnPopup, hero
        };

        const request = axios.post(config.url + '/admin/new-item-on-account', data,
            { headers: { 'authorization': 'Bearer ' + localStorage.getItem('token') } })

        toast.promise(request, {
            loading: 'Запрос отправляется...',
            success: 'Запрос отправлен!',
            error: (data) => 'Ошибка: ' + data.request?.responseText || data.message,
        })
    }

    return (<div className="big-block-desktop">
        <Input
            placeholder="accountId"
            value={accountId}
            onChange={(event) => setAccountId(event.target.value)}
        />
        <Input
            placeholder="Name"
            value={name}
            onChange={(event) => setName(event.target.value)}
        />
        <Input
            placeholder="Src"
            value={src}
            onChange={(event) => setSrc(event.target.value)}
        />
        <Input
            placeholder="Rarity"
            value={rarity}
            onChange={(event) => setRarity(event.target.value)}
        />
        <Input
            placeholder="Type"
            value={type}
            onChange={(event) => setType(event.target.value)}
        />
        <Input
            placeholder="Side"
            value={side}
            onChange={(event) => setSide(event.target.value)}
        />
        <Input
            placeholder="ShowsOnPopup"
            value={showsOnPopup}
            onChange={(event) => setShowsOnPopup(event.target.value)}
        />
        <Input
            placeholder="Hero"
            value={hero}
            onChange={(event) => setHero(event.target.value)}
        />
        <Button onClick={() => handleSubmit()}>Отправить</Button>

    </div>)

}

export default NewItem
