import React, { useEffect, useState } from 'react'
import * as api from '../api'
import { AxiosError } from 'axios'
import toast from 'react-hot-toast'
import AccountCard from './AccountCard'
import { Card } from 'semantic-ui-react'
import SortAccounts from './SortAccounts'
import LoadingCard from './AccountCard/LoadingCard'
import { isMobile } from 'react-device-detect'


const Accounts = () => {
    const [accountsData, setAccounts] = useState<api.Taccount[]>([])

    // Fetch accounts from the backend and populate them with notes
    function fetchAccounts() {
        api.getAccounts()
            .then(async res => {
                const previousAccounts = accountsData;
                const newAccounts = previousAccounts.length === 0 ? onlyOkAccs(res) : updateSavingOrder(previousAccounts, res);

                const allIds = newAccounts.map(acc => acc._id);
                const notes = await api.getNotes(...allIds);

                // Populate the accounts with the notes
                for (const acc of newAccounts) {
                    try {
                        const accNotes = notes.filter(n => n.accountId === acc._id);
                        acc.notes = accNotes ? accNotes : [];
                    } catch (error) {
                        handleApiError('Error getting notes from backend: ', error);
                        acc.notes = [];
                    }
                }

                setAccounts(newAccounts);
            })
            .catch(error => {
                handleApiError('Error getting accounts from backend: ', error);
            });

        // Error handling function
        function handleApiError(message, error) {
            toast.error(message + (error.message || 'Unknown error'));
        }
    }

    let Accounts = accountsData.map(acc => <AccountCard account={acc} key={acc._id} fetchAccounts={fetchAccounts} />)

    if (accountsData.length === 0)
        Accounts = Array(10).fill(0).map((_, i) => <LoadingCard key={i} />)

    useEffect(fetchAccounts, [])

    const wrapperClass = isMobile ? 'big-block-mobile' : 'big-block-desktop big-block-margin'
    return (
        <div className={wrapperClass}>
            <SortAccounts accounts={accountsData} setAccounts={setAccounts} />

            <Card.Group centered>
                {Accounts}
            </Card.Group>
            <br />
        </div>
    )
}

export default Accounts

function updateSavingOrder(prevAccounts: api.Taccount[], fetchedAccounts: api.Taccount[]): api.Taccount[] {
    const newList: api.Taccount[] = []

    for (const prevAcc of prevAccounts) {
        for (const currAcc of fetchedAccounts) {
            if (!isAccountGood(currAcc)) continue

            if (currAcc._id === prevAcc._id) {
                newList.push(currAcc)
                break
            }
        }
    }

    return newList
}

function onlyOkAccs(allAccs: api.Taccount[]): api.Taccount[] {
    return allAccs.map(acc => {
        if (!isAccountGood(acc))
            acc.notVisible = true
        return acc
    })
}

function isAccountGood(acc: api.Taccount): boolean {
    return !(acc.status === 'banned' || acc.status === 'temporary-retrieved')
}
