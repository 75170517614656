import { useEffect, useRef, useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, Tooltip } from 'recharts';
import { getOrdersFromDaysStats, getRevenueStatistics, TRevenueStats } from "../../api";
import { Card, List, Popup, Statistic } from "semantic-ui-react";


export type _TstatsOrdersFromDays = {
    day: number
    ordersAmount: number
    revenue: number
}[]

export default function OrdersFromDays() {
    const [data, setData] = useState<any[]>([])
    const [revenueStats, setRevenueStats] = useState<{
        currentMonth: TRevenueStats,
        pastMonth: TRevenueStats,
        last30Days: TRevenueStats
    } | null>(null);
    const pDiv = useRef<HTMLDivElement>(null)

    useEffect(() => {
        getOrdersFromDaysStats().then((res) => {
            setData(res)
        })

        getRevenueStatistics().then((res) => {
            setRevenueStats(res);
        });
    }, [])

    const renderStatisticsCard = (header: string, stats: TRevenueStats, statsToCompare?: TRevenueStats) => {
        const compareValues = (current: number, compare?: number) => !!compare && current > compare;

        const createStatistic = (label: string, value: number, isPositive: boolean) => (
            <Statistic key={label} label={label} value={value} color={isPositive ? 'green' : undefined} />
        );

        const rowOfTwo = (statsArray: { label: string, value: number, isPositive: boolean }[]) => (
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                {statsArray.map(stat => createStatistic(stat.label, stat.value, stat.isPositive))}
            </div>
        );

        return (
            <div style={{ margin: '20px auto 50px auto' }}>
                <Card style={{ width: '350px' }}>
                    <Card.Content>
                        <Card.Header>{header}</Card.Header>
                    </Card.Content>
                    <Card.Content textAlign="center">
                        {rowOfTwo([
                            {
                                label: 'Replenishments Count',
                                value: stats.replenishmentsCount,
                                isPositive: compareValues(stats.replenishmentsCount, statsToCompare?.replenishmentsCount)
                            },
                            {
                                label: 'Replenishments Total (USD)',
                                value: stats.replenishmentsTotalUsd,
                                isPositive: compareValues(stats.replenishmentsTotalUsd, statsToCompare?.replenishmentsTotalUsd)
                            }
                        ])}
                        <br />
                        {rowOfTwo([
                            {
                                label: 'Orders Count',
                                value: stats.ordersCount,
                                isPositive: compareValues(stats.ordersCount, statsToCompare?.ordersCount)
                            },
                            {
                                label: 'Orders Total (USD)',
                                value: stats.ordersTotalUsd,
                                isPositive: compareValues(stats.ordersTotalUsd, statsToCompare?.ordersTotalUsd)
                            }
                        ])}
                        <Popup
                            trigger={createStatistic(
                                "Active Users",
                                stats.activeUsers.length,
                                compareValues(stats.activeUsers.length, statsToCompare?.activeUsers.length)
                            )}
                            content={<List items={stats.activeUsers} />}
                            hoverable
                        />
                    </Card.Content>
                </Card>
            </div>
        );
    };


    return (
        <div className="big-block-desktop">

            <div ref={pDiv} style={{ padding: '20px 0 20px 0', textAlign: 'center' }} className="major-header">Статистика по заказам</div>
            <LineChart
                width={(pDiv.current?.clientWidth) as number - 40}
                height={400}
                data={data}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis min={DD_MM(Date.now() - 1000 * 3600 * 24 * 30)} max={DD_MM(Date.now())} dataKey="day" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line name="Количество заказов" type="monotone" dataKey="ordersAmount" stroke="#c2332f" />
                <Line name="Выручка, $" type="monotone" dataKey="revenue" stroke="#82ca9d" />
            </LineChart>

            {revenueStats && (
                <div style={{ marginTop: '20px', padding: '20px 0', textAlign: 'center' }} className="major-header">Revenue Statistics</div>
            )}
            {revenueStats && (
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'top' }}>
                    {renderStatisticsCard('Current Month', revenueStats.currentMonth, revenueStats.pastMonth)}
                    {renderStatisticsCard('Past Month', revenueStats.pastMonth)}
                    {renderStatisticsCard('Last 30 Days', revenueStats.last30Days)}
                </div>
            )}
        </div>
    )
}

function DD_MM(timestamp: number) {
    const date = new Date(timestamp);
    const yyyy = date.getFullYear();
    let mm = '' + date.getMonth() + 1; // Months start at 0!
    let dd = '' + date.getDate();

    if (+dd < 10) dd = '0' + dd;
    if (+mm < 10) mm = '0' + mm;

    const formattedToday = dd + '/' + mm + '/' + yyyy;

    return formattedToday
}