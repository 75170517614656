import React from "react";
import { Dropdown, Grid } from "semantic-ui-react";
import { Tquality, _TstatsProps } from "./OrdersFromMmr.js";
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import config from "../../config";


const dayToMs = 24 * 3600 * 1000
// value is time ms
const options = [
    { key: '30d-period', text: '30 дней', value: 30 * dayToMs },
    { key: '90d-period', text: '90 дней', value: 90 * dayToMs },
]

const DataSelection = (props: _TstatsProps) => {
    const marks =
        new Array(6).fill(0).reduce((accumulator, current, idx) => {
            accumulator[idx * 1000] = idx * 1000
            return accumulator
        }, {})

    return (
        <Grid style={{marginTop: '0px'}}>
            <Grid.Row columns={3} textAlign='center'>
                <Grid.Column>
                    <br />
                    <Range
                        style={{ width: '70%', display: 'inline-block' }}
                        min={config.minMmr}
                        max={config.maxMmr}
                        defaultValue={[config.minMmr, config.maxMmr]}
                        marks={marks}
                        pushable={500}
                        onChange={(changedValue) => {
                            const min = changedValue[0]
                            const max = changedValue[1]
                            props.setStartMmr(min)
                            props.setEndMmr(max)
                        }}
                    />
                </Grid.Column>
                <Grid.Column>Качество данных
                    <br />
                    <Slider
                        style={{ width: '70%', display: 'inline-block' }}
                        min={5}
                        max={20}
                        defaultValue={10}
                        // defaultValue={[5, 20]}
                        marks={{ 5: 5, 10: 10, 15: 15, 20: 20 }}
                        // pushable={5}
                        step={5}
                        onChange={(changedValue) => {
                            props.setQuality(changedValue as Tquality)
                        }}
                    />
                </Grid.Column>
                <Grid.Column>
                    <br />
                    <Dropdown placeholder='time period' fluid value={props.period}
                        style={{ width: '70%', display: 'inline-block' }}
                        onChange={(_, data) => props.setPeriod(data.value as number)}
                        selection defaultValue={options[0].value} options={options} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default DataSelection
