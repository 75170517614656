import React, { useRef } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, Tooltip } from 'recharts';
import { TorderStatistics } from "../../api";
import { _TstatsProps } from "./OrdersFromMmr";



const OrdersStats = ({ data, props }: { props: _TstatsProps, data: TorderStatistics[] }) => {
    const pDiv = useRef<HTMLDivElement>(null)
    const filteredData = data.filter(el => el.mmrRange >= props.startMmr && el.mmrRange <= props.endMmr)

    return (
        <div className="big-block-desktop">
            <div ref={pDiv} style={{ padding: '20px 0 20px 0', textAlign: 'center' }} className="major-header">
                Статистика по заказам</div>
            <LineChart
                width={(pDiv.current?.clientWidth) as number - 40}
                height={450}
                data={filteredData}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis min={props.startMmr} max={props.endMmr + props.startMmr} dataKey="mmrRange" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line name="Всего заказов * 0.1" type="monotone" dataKey="deciOrdersAmount" stroke="#c2332f" />
                <Line name="Выручка * 0.1" type="monotone" dataKey="deci$" stroke="#82ca9d" />
                <Line name="Доступны для аренды сейчас" type="monotone" dataKey="activeAccsAmount" stroke="#8884d8" />
            </LineChart>
        </div>
    )
}

export default OrdersStats