import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { Button, Card, Input, TextArea } from "semantic-ui-react";
import * as db from '../../db'



const Notes = () => {
    const [notes, setNotes] = useState<db.TlocalNote[]>([])
    const [textPart, setTextPart] = useState('')

    const [newNoteText, setNewNoteText] = useState('')

    const inputStyle = { width: '300px', }
    const btnStyle = { width: '160px', marginLeft: '30px' }


    const upd = () => db.findNotes(undefined, undefined, '', 300).then(setNotes)
    useEffect(() => {
        window.dbListeners.onUpdate = upd
        upd()
    }, [])

    const findNotes = () => {
        db.findNotes(undefined, undefined, textPart, 300).then(setNotes)
    }

    const notesList = notes.length > 0 ? notes.map(note => <Note note={note} key={note.id} setNewNotes={upd} />)
        : <h2 style={{ margin: '10px' }}>Пусто</h2>

    return (<>
        <div className="standard-divider"></div>
        <div className='big-block-desktop '>
            <div className='internal-block'>
                <Input placeholder='Часть слова'
                    value={textPart}
                    style={inputStyle}
                    size='large'
                    onChange={(e, { value }) => { setTextPart(value as string) }}
                />
                <Button size='large' style={btnStyle} onClick={findNotes}>
                    Поиск
                </Button>

                <br /> <br />

                <Input
                    placeholder='Текст заметки'
                    style={inputStyle} size='large'
                    value={newNoteText} onChange={(e, { value }) => { setNewNoteText(value as string) }}
                />
                <Button style={btnStyle} color='blue' content='Создать' size='large'
                    onClick={() => {
                        db.addNewNote(newNoteText).then(() => setNewNoteText(''))
                    }} />
            </div>

            <div className='internal-block'>
                <br />
                <Card.Group centered>{notesList}</Card.Group>
            </div>
            <br />
        </div>

    </>)
};


type noteProps = {
    note: db.TlocalNote
    setNewNotes: () => void
}
const Note = ({ note, setNewNotes }: noteProps) => {
    const [isOpenedEditMenu, setOpenEditMenu] = useState(false)
    const [editText, setEditText] = useState(note.text)
    const [textHeightPx, setTextHeightPx] = useState(0)
    const textRef = useRef<HTMLDivElement | null>(null)

    const noteWrapper: React.CSSProperties = {
        // margin: '16px',
        // width: '28%',
    }

    useEffect(() => {
        setTextHeightPx((textRef && textRef.current) ? textRef.current.offsetHeight : 0)
    }, [textRef])

    const setNewText = () =>
        db.editNote(note.id, editText)
            .catch(err => toast.error('Ошибка редактирования заметки:', err.message))
            .then(res => {
                toast.success('Заметка успешно отредактирована')
                setOpenEditMenu(false)
                setNewNotes()
                console.log('edited note', note.id, res)
            })

    const deleteNote = () =>
        db.deleteNote(note.id)
            .catch(err => toast.error('Ошибка удаления заметки:', err.message))
            .then(res => {
                toast.success('Заметка успешно удалена')
                setNewNotes()
                console.log('deleted note', note.id, res)
            })

    if (!isOpenedEditMenu)
        return <Card
            style={noteWrapper}
        >
            <Card.Content><div ref={textRef}>
                {/* <Card.Header extra content={note.title} /> */}
                <Card.Description content={note.text} />
            </div></Card.Content>
            <Card.Content extra>
                <div className='ui two buttons'>
                    <Button basic color='green' onClick={() => setOpenEditMenu(true)}>
                        Редактировать
                    </Button>
                    <Button basic color='red' onClick={deleteNote}>
                        Удалить
                    </Button>
                </div>
            </Card.Content>
        </Card>
    else
        return <Card
            style={noteWrapper}
        >
            <Card.Content style={{ paddingBottom: '0px' }}>
                <TextArea style={{ width: '100%', maxWidth: '100%', minWidth: '100%', height: textHeightPx + 22 + 'px' }}
                    onChange={(e) => setEditText(e.target.value)} value={editText} />
            </Card.Content>
            <Card.Content extra>
                <div className='ui two buttons'>
                    <Button basic color='green' onClick={setNewText}>
                        Сохранить
                    </Button>
                    <Button onClick={() => {
                        setOpenEditMenu(false)
                        setEditText(note.text)
                    }} basic color='red'>
                        Отменить
                    </Button>
                </div>
            </Card.Content>
        </Card>
}

export default Notes;