import { _TstatsOrdersFromDays } from '../components/Stats/OrdersFromDays'
import axios, { AxiosRequestConfig } from "axios"
import config from "../config"


export type Tnote = {
    _id: string
    text: string
    accountId: string
    createdAt: string
    updatedAt: string
}

export type Tstatus = 'available-for-rent'
    | 'rented'
    | 'screenshots-not-made'
    | 'making-screenshots'
    | 'screenshots-were-made'
    | 'changing-password'
    | 'moderation'
    | 'banned'
    | 'temporary-retrieved'

export type Taccount = {
    _id: string

    login: string
    password: string

    status: Tstatus

    rating: number
    behavior: number
    trophyLevel: number
    tokens: number
    ammountOfLpOnAccount: number
    confidence: number

    costPerGame: number

    notVisible?: boolean
    notes: Tnote[]
}

export type TsendNewAccountData = {
    _id: string
    path: string
    tokens: number
    mmr: number
    confidence: number
    behavior: number
    trophylvl: number
    lpAmount: number
    banned: boolean
}

export type TorderStatistics = {
    key: string
    deci$: number
    mmrRange: number
    // deciOrdersAmount: number
    // activeAccsAmount: number
}





const url = config.url
const auth: AxiosRequestConfig = {
    headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
    }
}

export const getAccounts = (): Promise<Taccount[]> =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await axios.get<Taccount[]>(url + '/moderator/accounts', auth)

            resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })

export const makeNewScreenshots = async (accountId: string, pathToScrs?: string, deleteOld?: true): Promise<object | void> =>
    axios.post(url + '/moderator/request-new-screenshots',
        { accountId, pathToScrs, shouldDeleteOldScrs: deleteOld }, auth)

export const changePassword = async (accountId: string): Promise<object | void> =>
    axios.post(url + '/moderator/request-change-password', { accountId }, auth)

export function sendNewAccountData(data: TsendNewAccountData): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
        try {
            const { mmr, tokens, behavior, _id, confidence } = data
            const postdata = {
                mmr, confidence, tokens, behavior, _id,

                dirname: data.path,
                lvl: data.trophylvl,
                amountOfLP: data.lpAmount,
                isBan: data.banned,
            }
            await axios.post(url + '/update-account-data', postdata, auth)
            resolve(true)
        } catch (error) {
            console.error('error in sendNewAccountData', error)
            reject(error)
        }
    })
}

export const getPaths = (): Promise<string[]> => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios.get<string[]>(url + '/get-unchecked-accounts', auth)
            resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })
}

export const changeStatus = <T>(accountId: string, newStatus: T): Promise<boolean> =>
    new Promise(async (resolve, reject) => {
        try {
            await axios.post(url + '/moderator/change-account-status', { accountId, newStatus }, auth)
            resolve(true)
        } catch (error) {
            reject(error)
        }
    })

export const getNotes = async (...ids: string[]): Promise<Tnote[]> =>
    new Promise(async (resolve, reject) => {
        try {
            const accIds = '?accountIds=' + JSON.stringify(ids)
            const response = await axios.get<Tnote[]>(url + '/moderator/note' + accIds, auth)
            resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })

export const addNote = async (text: string, accountId: string): Promise<Tnote> =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await axios.put<Tnote>(url + '/moderator/note', { accountId, text }, auth)
            resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })

export const editNote = async (noteId: string, text: string): Promise<boolean> =>
    new Promise(async (resolve, reject) => {
        try {
            await axios.post(url + '/moderator/note', { _id: noteId, text }, auth)
            resolve(true)
        } catch (error) {
            reject(error)
        }
    })

export const deleteNote = async (noteId: string): Promise<boolean> =>
    new Promise(async (resolve, reject) => {
        try {
            await axios.delete(url + '/moderator/note/', { ...auth, data: { _id: noteId } })
            resolve(true)
        } catch (error) {
            reject(error)
        }
    })

export const isAdmin = (): Promise<boolean> =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await axios.get<boolean>(url + '/admin/is-admin', auth)
            resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })

export const getOrdersStatistics = (period: number, quality: number, startMmr: number, endMmr: number):
    Promise<TorderStatistics[]> =>
    new Promise(async (resolve, reject) => {
        try {
            const urlparams = new URLSearchParams()
            urlparams.set('period', period.toString())
            urlparams.set('quality', quality.toString())
            urlparams.set('startMmr', startMmr.toString())
            urlparams.set('endMmr', endMmr.toString())
            const params = '?' + urlparams.toString()
            const response = await axios.get(url + '/admin/orders/statistics' + params, { ...auth })
            resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })

export const getOrdersFromDaysStats = (): Promise<_TstatsOrdersFromDays> =>
    new Promise(async (resolve, reject) => {
        try {
            const response = await axios.get<_TstatsOrdersFromDays>(url + '/admin/orders/from-days', { ...auth })
            resolve(response.data)
        } catch (error) {
            reject(error)
        }
    })

type _TstatsAccsFromMmr = { amount: number, rating: number }[]
export const getAccountsFromMmr = async (
    minBehavior: number, maxBehavior: number,
    minMmr: number, maxMmr: number
): Promise<_TstatsAccsFromMmr> => {
    type _returnType = {
        ratingStep: number
        totalAccounts: number
        statistics: _TstatsAccsFromMmr
    }
    const reqcfg = { ...auth, params: { minBehavior, maxBehavior, maxRating: maxMmr, minRating: minMmr } }
    const response = await axios.get<_returnType>(url + '/admin/accounts/statistics', reqcfg)
    return response.data.statistics
}

export type TRevenueStats = {
    replenishmentsCount: number
    replenishmentsTotalUsd: number
    ordersCount: number
    ordersTotalUsd: number
    activeUsers: string[]; // Users that made order in period
}
export const getRevenueStatistics = async (): Promise<{
    currentMonth: TRevenueStats,
    pastMonth: TRevenueStats,
    last30Days: TRevenueStats
}> => {
    const response = await axios.get(url + '/admin/revenue/statistics', auth)
    return response.data
}

export function updateAccountData(
    _id: string,
    rating: number, confidence: number, tokens: number, behavior: number, trophy: number,
    lp: number, banned: boolean
) {
    const params = new URLSearchParams()

    params.append("_id", _id)
    params.append("rating", rating.toString())
    params.append("confidence", confidence.toString())
    params.append("tokens", tokens.toString())
    params.append("behavior", behavior.toString())
    params.append("trophy", trophy.toString())
    params.append("lp", lp.toString())
    params.append("banned", banned.toString())

    return axios.put(url + '/moderator/account?' + params, {}, auth)
}
