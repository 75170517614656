import React, { useEffect, useState, useRef } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, Tooltip } from 'recharts';
import { getAccountsFromMmr } from "../../api";
import { Range } from "rc-slider";
import useDebouncedEffect from "../../utils/useDebouncedEffect";




type _Tstats = {
    amount: number
    rating: number // if rating is 400 then account rating was 400-600
}[]
const AccountsFromMmr = () => {
    const behaviorStep = 2000
    const mmrStep = 1000
    const pDiv = useRef<HTMLDivElement>(null)

    const [minBehavior, setMinBehavior] = useState(7000)
    const [maxBehavior, setMaxBehavior] = useState(12000)

    const [minMmr, setMinMmr] = useState(0)
    const [maxMmr, setMaxMmr] = useState(7000)

    const [stats, setStats] = useState<_Tstats>([])
    useDebouncedEffect(() => {
        getAccountsFromMmr(minBehavior, maxBehavior, minMmr, maxMmr)
            .then(stats => stats.filter(s => s.rating >= minMmr && s.rating <= maxMmr))
            .then(s => setStats(s))
    }, [minBehavior, maxBehavior, minMmr, maxMmr], 200)

    return (
        <div className="big-block-desktop">
            <div ref={pDiv} style={{ padding: '20px 0 20px 0', textAlign: 'center' }}
                className="major-header">Количество аккаунтов по ммр</div>

            {/* Filters block */}
            <div style={{margin: '20px auto 40px auto'}}>

                <Range
                    style={{ width: '40%', marginLeft: "5%", display: 'inline-block' }}
                    min={0}
                    max={12000}
                    defaultValue={[minBehavior, maxBehavior]}
                    pushable={500}
                    marks={new Array(12000 / behaviorStep + 1).fill(0).reduce((accumulator, current, idx) => {
                        accumulator[idx * behaviorStep] = idx * behaviorStep
                        return accumulator
                    }, {})}
                    onChange={(changedValue) => {
                        const min = changedValue[0]
                        const max = changedValue[1]
                        setMinBehavior(min)
                        setMaxBehavior(max)
                    }}
                />
                <Range
                    style={{ width: '40%', marginLeft: "5%", display: 'inline-block' }}
                    min={0}
                    max={10000}
                    defaultValue={[minMmr, maxMmr]}
                    pushable={200}
                    marks={new Array(10000 / mmrStep + 1).fill(0).reduce((accumulator, _, idx) => {
                        accumulator[idx * mmrStep] = idx * mmrStep
                        return accumulator
                    }, {})}
                    onChange={(changedValue) => {
                        const min = changedValue[0]
                        const max = changedValue[1]
                        setMinMmr(min)
                        setMaxMmr(max)
                    }}
                />
            </div>

            <LineChart
                width={(pDiv.current?.clientWidth) as number - 40}
                height={450}
                data={stats}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis min={minMmr} max={maxMmr} dataKey="rating" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line name="Аккаунтов в промежутке" type="monotone" dataKey="amount" stroke="#8884d8" />
            </LineChart>
        </div>
    )
}

export default AccountsFromMmr
