import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { Button, Label } from "semantic-ui-react"
import { getPaths, isAdmin as apiIsAdmin } from "../api"
import { isMobile } from "react-device-detect"
import toast from "react-hot-toast"


const Header = () => {
    const [notDoneScrsAmount, setNotDoneScrsAmount] = React.useState(0)
    const [isAdmin, setIsAdmin] = React.useState(false)

    const labelForScrs = notDoneScrsAmount > 0 ? notDoneScrsAmount.toString() : undefined

    useEffect(() => {
        apiIsAdmin()
            .then(result => result === true ? setIsAdmin(true) : setIsAdmin(false))
            .catch((e) => {
                setIsAdmin(false)
                console.error('error apiIsAdmin', e)
            })

        function updateScrenshotsCount() {
            getPaths().then((paths) => {
                if (paths.length > 0)
                    document.title = `Новые скрины: ${paths.length}`
                setNotDoneScrsAmount(paths.length)
            })
        }

        updateScrenshotsCount()
        const interval = setInterval(updateScrenshotsCount, 10_000)
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        // give a link in a toast to https://157.rentdota2.com/ if the user is on http://*
        if (window.location.protocol === 'http:')
            toast((t) => (
                <span>
                    You are using an insecure connection. Please use <a href="https://157.rentdota2.com/">https://157.rentdota2.com/</a>
                </span>
            ))
    }, [])

    return (
        <div className='big-block-desktop'>
            <div className='internal-block'>
                <SwitchButton text="Аккаунты" link='/' />
                <SwitchButton text="Скриншоты" link='/screenshots' label={labelForScrs} />
                <SwitchButton text="Заметки" link='/notes' />
                {isAdmin ? <SwitchButton text="Статистика" link="/admin/stats/orders-days" /> : null}
                {isAdmin ? <SwitchButton text="Новый аккаунт" link="/admin/accounts/new" /> : null}
                {isAdmin ? <SwitchButton text="Шмотки" link="/admin/items/new" /> : null}
                {isMobile ?
                    <SwitchButton text='Настройки' link='/paste-token'
                    /> : null}
            </div>
        </div>
    )
}


export const SwitchButton = ({ text, link, label }: { text: string, link: string, label?: string }) => (
    <Link to={link}>
        <div style={{ display: 'inline-block', margin: '0 32px 0 0' }}>
            <Button
                size='large'
                color="teal"
                content={text}
            />
            {label ? <Label basic color='red' pointing='left' content={label} /> : null}
        </div>
    </Link>
)

export default Header