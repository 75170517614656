import toast from 'react-hot-toast'
import * as api from './api'


export const changePassword = (_id: string) => {
    const request = api.changePassword(_id)

    return toast.promise(request, {
        loading: 'Запрос отправляется...',
        success: 'Запрос отправлен!',
        error: (data) => 'Ошибка: ' + data.request?.responseText || data.message,
    })
}
export const makeScreenshots = (_id: string, pathToScrs?: string, deleteOld?: true) => {
    console.log('makeScreenshots. pathToScrs: ', pathToScrs, 'deleteOld: ', deleteOld)
    const request = api.makeNewScreenshots(_id, pathToScrs, deleteOld)

    return toast.promise(request, {
        loading: 'Запрос отправляется...',
        success: 'Запрос успешно отправлен',
        error: (data: Error) => 'Ошибка при отправке запроса: ' + data.message,
    })
}
