import React, { useState } from 'react'
import * as api from '../../api'
import toast from 'react-hot-toast'
import { Button, Card, Dropdown, Image } from 'semantic-ui-react'
import { getStatusText } from './_utils'
import './card.css'
import { changePassword, makeScreenshots } from '../../utils'
import EditNotes from './EditNotes'
import EditAccountData from './EditAccData'
import NewNoteInput from './NewNoteInput'
import config from '../../config'
import { AccountLogs } from './AccountLogs'


type TchangeStatus = "available-for-rent" | "moderation" | 'temporary-retrieved'

const options: { key: string, text: string, value: TchangeStatus }[] = [
    { key: 'status-1', text: 'доступен для аренды', value: 'available-for-rent' },
    { key: 'status-2', text: 'на модерации', value: 'moderation' },
    { key: 'status-3', text: 'временно восстановлен', value: 'temporary-retrieved' },
]


const AccountCard = ({ account, fetchAccounts }: { account: api.Taccount, fetchAccounts: Function }) => {
    const [status, setStatus] = useState<TchangeStatus>(options[0].value)
    const linkToAccount = 'https://rentdota2.com/api/v1/account-card/' + account._id + '.jpg'

    const sendStatus = async () => {
        const request = api.changeStatus<TchangeStatus>(account._id, status)

        await toast.promise(request, {
            loading: 'Изменение статуса',
            success: 'Статус изменен',
            error: 'Ошибка изменения статуса',
        })

        fetchAccounts()
    }

    const twoButtons: React.CSSProperties = {
        marginTop: '6px', marginBottom: '12px',
        display: 'flex', alignItems: 'center',
        justifyContent: 'space-around'
    }
    const priceStyles: React.CSSProperties = {
        position: 'absolute',
        zIndex: '1',
        color: 'white',
        // width: '100%',
        padding: '5px',
        fontSize: '20px',
        marginLeft: '224px',
        marginTop: '80px',
    }
    const editWrapperStyles: React.CSSProperties = {
        position: 'absolute',
        zIndex: '1',
        padding: '5px',
        marginLeft: '190px',
        marginTop: '10px',
    }
    const accountLogsStyle: React.CSSProperties = {
        position: 'absolute',
        zIndex: '1',
        padding: '5px',
        marginLeft: '150px',
        marginTop: '10px',
    }
    const buttonStyle: React.CSSProperties = {
        width: '130px',
    }
    const newLineStyle: React.CSSProperties = {
        padding: '4px 14px 4px 14px',
    }
    const grid2colStyle: React.CSSProperties = {
        display: 'grid',

        gridTemplateColumns: '50% 50%',
        gridGap: '8px',
    }
    const grid3colStyle: React.CSSProperties = {
        display: 'grid',
        textAlign: 'center',

        gridTemplateColumns: '33% 33% 33%',
        gridGap: '0px',
    }

    const style = {
        warnValues: { color: 'red' },
        badValues: { color: 'red', fontWeight: 'bold', fontSize: '105%' },
    }

    const LP = account.ammountOfLpOnAccount === 0
        ? '0' : <span style={style.warnValues}>{account.ammountOfLpOnAccount}</span>
    let tokens
    if (account.tokens >= 20)
        tokens = <span>{account.tokens}</span>
    else if (account.tokens >= config.minTokens)
        tokens = <span style={style.warnValues}>{account.tokens}</span>
    else
        tokens = <span style={style.badValues}>{account.tokens}</span>

    let behavior
    if (account.behavior >= config.minBehavior)
        behavior = <span>{account.behavior}</span>
    else if (account.behavior >= config.closeBehavior) // close to being shown on a website (needs polishing)
        behavior = <span style={style.warnValues}>{account.behavior}</span>
    else
        behavior = <span style={style.badValues}>{account.behavior}</span>

    let rating
    if (account.rating >= 0)
        rating = <span>{account.rating}</span>
    else // calibrating (marked as negative value)
        rating = <span style={{ color: 'darkblue' }}>{account.rating}</span>

    if (account.notVisible) return null

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text)
        toast.success('Скопировано в буфер обмена:\n' + text, { duration: 2000, icon: '📋' })
    }
    const AccountData = () => (<>
        <div style={newLineStyle}>Account ID: {account._id}</div>
        <div
            style={newLineStyle}
            className='copyable'
            onClick={() => copyToClipboard(account.login + ' ' + account.password)}>
            Логин: {account.login}
        </div>
        <div
            style={newLineStyle}
            className='copyable'
            onClick={() => copyToClipboard(account.password)}>
            Пароль: {account.password}
        </div>
        <div style={grid2colStyle}>
            <div style={newLineStyle}>Рейтинг: {rating}</div>
            <div style={newLineStyle}>Порядка: {behavior}</div>
        </div>
        <div style={{ ...grid3colStyle, ...newLineStyle }}>
            <div style={{ textAlign: 'left' }}>Жетоны: {tokens}</div>
            <div>Лп: {LP}</div>
            <div>Лвл: {account.trophyLevel}</div>
        </div>
    </>)

    return (
        <Card className='account-card-wrapper'>
            <div style={editWrapperStyles}> <EditAccountData account={account} fetchAccounts={fetchAccounts} /> </div>
            <div style={accountLogsStyle}> <AccountLogs accountId={account._id} /> </div>

            <div>
                <div style={priceStyles}>{account.costPerGame} $</div>
                <Image style={{ borderRadius: '.28571429rem' }} src={linkToAccount} />
            </div>
            <Card.Content content={getStatusText(account.status)} />

            <AccountData />

            <NewNoteInput account={account} updateAccount={fetchAccounts} />

            <div style={twoButtons}>
                <Button style={buttonStyle} size='mini' positive onClick={async () => {
                    await changePassword(account._id)
                    fetchAccounts()
                }}>
                    Поменять пароль
                </Button>
                <Button style={buttonStyle} size='mini' positive onClick={async () => {
                    await makeScreenshots(account._id)
                    fetchAccounts()
                }}>
                    Сделать скрины
                </Button>
            </div>
            <div style={twoButtons}>
                <ChangeStatusButton status={status} sendStatus={sendStatus} setStatus={setStatus} />
                <EditNotes accountId={account._id} />
            </div>
        </Card>
    )
}


const ChangeStatusButton = ({ status, sendStatus, setStatus }) => (
    <Button.Group size='mini' color='teal' style={{}}>
        <Button onClick={sendStatus}>Поменять статус</Button>
        <Dropdown
            className='button icon'
            floating value={status} onChange={(e, { value }) => {
                setStatus(value as TchangeStatus)
            }}
            options={options}
            trigger={<></>}
        />
    </Button.Group>
)

export default AccountCard