import React, { useEffect, useState } from "react";
import config from "../../config";
import DataSelection from "./DataSelection";
import { getOrdersStatistics, TorderStatistics } from "../../api";
import useDebouncedEffect from "../../utils/useDebouncedEffect";
import OrdersStats from "./OrdersStats";


export type Tquality = 5 | 10 | 15 | 20

export type _TstatsProps = {
    period: number
    setPeriod: React.Dispatch<React.SetStateAction<number>>

    quality: Tquality
    setQuality: React.Dispatch<React.SetStateAction<Tquality>>

    startMmr: number
    setStartMmr: React.Dispatch<React.SetStateAction<number>>

    endMmr: number
    setEndMmr: React.Dispatch<React.SetStateAction<number>>
}

const OrdersFromMmr = () => {
    const [ordersStats, setOrdersStats] = useState<TorderStatistics[]>([])


    // period from [now - period ; now]
    const [period, setPeriod] = useState(30 * 24 * 3600 * 1000) // 30 days

    // it is step how frequest data should be divided
    const [quality, setQuality] = useState<Tquality>(10) // 10 means dispay every 100 mmr, 20 - every 200
    const [startMmr, setStartMmr] = useState(config.minMmr)
    const [endMmr, setEndMmr] = useState(config.maxMmr)

    function updateStats() {
        const stats = getOrdersStatistics(period, quality, startMmr, endMmr)
            .then(stats =>
                setOrdersStats(stats)
            )
    }
    useEffect(() => updateStats(), [])
    // useDebouncedEffect(updateStats, [quality, period, startMmr, endMmr], 500)

    const p: _TstatsProps = { period, setPeriod, quality, setQuality, startMmr, setStartMmr, endMmr, setEndMmr }
    return (
        <div className="big-block-desktop">
            <DataSelection {...p} />
            <OrdersStats props={p} data={ordersStats} />
        </div>
    )
}

export default OrdersFromMmr
