import React from "react";
import { Button, Input } from "semantic-ui-react";


const PasteToken = () => {
    const [token, setToken] = React.useState('');
    const style = { width: '80%', marginLeft: '10%', marginBottom: '10px' }

    return (
        <div style={{minHeight: '80vh'}}>
            <Input value={token} onChange={(e) => setToken(e.target.value)}
                fluid
                style={style}
                size="large"
                placeholder='Место для токена'
            />
            <Button
                style={style}
                fluid
                content='Установить новый токен'
                size="large"
                onClick={() => localStorage.setItem('token', token)}
            />
        </div>
    )
}

export default PasteToken
