import { useEffect } from 'react'


export type TlocalNote = {
    id: number,
    text: string,
    createdAt: number,
}


function onupgradeneeded(db: IDBDatabase) {
    if (!db.objectStoreNames.contains('notes')) {
        let notes = db.createObjectStore('notes', { keyPath: 'id' })
        notes.createIndex('createdAtIndex', 'createdAt');
    };
}

export function generateId(shouldIncrement?: boolean) {
    let id: string | number | null = localStorage.getItem('id')
    if (id === null) {
        localStorage.setItem('id', '0')
        id = '0'
    }
    id = parseInt(id)

    if (shouldIncrement) {
        id += 1
        localStorage.setItem('id', String(id))
    }
    return id
}

export const editNote = (noteId: number, newText: string) =>
    new Promise((resolve, reject) => {
        console.log(noteId);
        let openRequest = indexedDB.open("database", 1)
        openRequest.onsuccess = function (event) {
            let db = openRequest.result
            let transaction = db.transaction("notes", 'readwrite')
            let notes = transaction.objectStore("notes");
            let request = notes.get(noteId)

            request.onsuccess = function (event) {
                let data: TlocalNote = request.result
                data.text = newText
                let requestUpdate = notes.put(data)
                requestUpdate.onerror = function (event) {
                    reject('Error updating memo')
                }
                requestUpdate.onsuccess = function (event) {
                    resolve('Memo updated successfully')
                    window.dbListeners.onUpdate()
                }
            }
        }
    })

export const findNotes = (dateFrom = 0, dateTo = Date.now(), textPart = '', limit = 20): Promise<TlocalNote[]> =>
    new Promise(async (resolve, reject) => {
        console.time('find notes')
        const resultNotes: TlocalNote[] = []
        // const amountOfNotesInDB = await getAmountOfNotesInDB()
        let openRequest = indexedDB.open("database", 1)

        openRequest.onupgradeneeded = () => onupgradeneeded(openRequest.result)

        openRequest.onsuccess = function () {
            let db = openRequest.result;

            let transaction = db.transaction("notes", "readonly")

            let notes = transaction.objectStore("notes");

            let index = notes.index('createdAtIndex')

            let request = index.openCursor(IDBKeyRange.bound(dateFrom, dateTo))

            let i = 0
            request.transaction?.addEventListener('complete', () => {
                resolve(resultNotes)
                console.timeEnd('find notes')
            })
            request.onsuccess = (e) => {
                if (i >= limit) {
                    console.timeEnd('find notes')
                    return resolve(resultNotes)
                }
                if (request.result?.value.text.includes(textPart)) {
                    resultNotes.push(request.result.value)
                    i += 1
                }
                request.result?.continue()
            }

            request.onerror = () => {
                console.log("Error adding memo", request.error);
                reject(request.error)
            }
        }

        openRequest.onerror = function () {
            reject(this.error)
        }
    })

export function addNewNote(text: string): Promise<TlocalNote> {
    const createdAt = new Date().getTime()

    return new Promise((resolve, reject) => {
        let openRequest = indexedDB.open("database", 1)

        openRequest.onupgradeneeded = () => onupgradeneeded(openRequest.result)

        openRequest.onsuccess = function () {
            let db = openRequest.result;
            let transaction = db.transaction("notes", "readwrite")
            let notes = transaction.objectStore("notes");

            const newNote: TlocalNote = {
                id: generateId(true),
                text,
                createdAt,
            }
            let request = notes.add(newNote)
            window.dbListeners.onUpdate()

            request.onsuccess = () => {
                console.log("Memo added", request.result);
                resolve(newNote)
            }

            request.onerror = () => {
                console.log("Error adding memo", request.error);
                reject(request.error)
            }
        }

        openRequest.onerror = function () {
            reject(this.error)
        }
    })
}


export const deleteNote = (noteId: number): Promise<boolean> =>
    new Promise((resolve, reject) => {
        let openRequest = indexedDB.open("database", 1)

        openRequest.onupgradeneeded = () => onupgradeneeded(openRequest.result)

        openRequest.onsuccess = function () {
            let db = openRequest.result;

            let transaction = db.transaction("notes", "readwrite")

            let notes = transaction.objectStore("notes")

            const request = notes.delete(noteId)
            window.dbListeners.onUpdate()

            request.onsuccess = () => resolve(true)
            request.onerror = () => reject(request.error)
        }
    });
