import React from 'react';
import { Toaster } from 'react-hot-toast'
import Accounts from './components/Accounts'
import Screenshots from './components/Screenshots';
import
{
    BrowserRouter as Router,
    Route, Routes
} from "react-router-dom";
import Header from './components/Header'
import PasteToken from './components/PasteToken';
import Stats from './components/Stats';
import Notes from './components/Notes';
import NewAccount from './components/NewAccount';
import NewItem from './components/NewItem';


function App ()
{
    return (
        <div id='app'>
            {/* Alerts */}
            <Toaster containerStyle={{ zIndex: 99999, marginTop: '55px' }} />
            <Router>
                <Header />
                <Routes>
                    <Route path={'/'} element={<Accounts />} />
                    <Route path={'/screenshots'} element={<Screenshots />} />
                    <Route path={'/notes'} element={<Notes />} />
                    <Route path={'/paste-token'} element={<PasteToken />} />
                    <Route path={'/admin/accounts/new'} element={<NewAccount />} />
                    <Route path={'/admin/items/new'} element={<NewItem />} />
                    <Route path={'/admin/stats/orders-days'} element={<Stats page='orders-days' />} />
                    <Route path={'/admin/stats/profit-mmr'} element={<Stats page='profit-mmr' />} />
                    <Route path={'/admin/stats/accounts-mmr'} element={<Stats page='accounts-mmr' />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
