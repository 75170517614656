import React from "react";
import { Card } from "semantic-ui-react";
import { Tstatus } from "../../api";


export function getStatusText(status: Tstatus) {
    const positive: React.CSSProperties = {
        color: "green"
    }
    const negative: React.CSSProperties = {
        color: "red"
    }

    if (status === 'available-for-rent')
        return <Card.Header style={positive}>
            Доступен для аренды
        </Card.Header>
    else if (status === 'banned')
        return <Card.Header style={negative}>
            Заблокирован
        </Card.Header>
    else if (status === 'rented')
        return <Card.Header style={positive}>
            Арендован
        </Card.Header>
    else if (status === 'moderation')
        return <Card.Header>
            На модерации
        </Card.Header>
    else if (status === 'changing-password')
        return <Card.Header>
            Пароль меняется...
        </Card.Header>
    else if (status === 'screenshots-were-made')
        return <Card.Header>
            Не внесены скриншоты
        </Card.Header>
    else if (status === 'screenshots-not-made')
        return <Card.Header>
            Не сделаны скриншоты
        </Card.Header>
    else if (status === 'making-screenshots')
        return <Card.Header>
            Скриншоты делаются...
        </Card.Header>
    else if (status === 'temporary-retrieved')
        return <Card.Header style={negative}>
            Временно восстановлен
        </Card.Header>
    else
        return <Card.Header style={negative}>
            ! Неизвестный статус !
        </Card.Header>
}
