import React, { useEffect, useState } from "react";
import * as api from '../../api'
import Account from "./Account";


const Screenshots = () => {
    const [remotePaths, setRemotePaths] = useState<string[]>([])

    useEffect(() => {
        api.getPaths().then((paths) => {
            setRemotePaths(paths)
        })
    }, [])


    return (
        <div className="big-block-desktop big-block-margin">
            <div className="internal-block">
                <div style={{ textAlign: 'center' }} className="major-header">Скриншоты</div>
            </div>
            {
                remotePaths.map(path =>
                    <Account path={path} key={path} />)
            }
        </div>
    )
}

export default Screenshots