import React from "react";
import { Card, Loader } from "semantic-ui-react";


const LoadingCard= () => {
    return (
        <Card style={{
            width: '340px',
            height: '400px',
        }} className='account-card-wrapper'>
            <div style={{
                height: '218.301px',
                width: '100%',
                backgroundColor: '#abb9ff',
            }}></div>
            <Loader size='massive' style={{marginTop: '50px'}} active inline='centered' />
        </Card>
    );
};

export default LoadingCard;