import axios from "axios";
import React from "react";
import { toast } from "react-hot-toast";
import { Button, Input } from "semantic-ui-react";
import config from "../config";


const NewAccount = () => {
    // component with fields: login, password, email, emailpassword.
    // send that to login, password, mail, mailPassword in put request to backend

    const [login, setLogin] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [mail, setMail] = React.useState("");
    const [mailPassword, setMailPassword] = React.useState("");

    const handleSubmit = () => {
        const data = {
            login,
            password,
            mail,
            mailPassword
        };

        const request = axios.put(config.url + '/admin/create-new-account', data,
            { headers: { 'authorization': 'Bearer ' + localStorage.getItem('token') } })

        toast.promise(request, {
            loading: 'Запрос отправляется...',
            success: 'Запрос отправлен!',
            error: (data) => 'Ошибка: ' + data.request?.responseText || data.message,
        })
    }

    return (<div className="big-block-desktop">
        <Input
            placeholder="Логин"
            value={login}
            onChange={(event) => setLogin(event.target.value)}
        />
        <Input
            placeholder="Пароль"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
        />
        <Input
            placeholder="Почта"
            value={mail}
            onChange={(event) => setMail(event.target.value)}
        />
        <Input
            placeholder="Пароль от почты"
            value={mailPassword}
            onChange={(event) => setMailPassword(event.target.value)}
        />
        <Button onClick={() => handleSubmit()}>Создать аккаунт</Button>

    </div>)

}

export default NewAccount;
