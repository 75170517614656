import React, { useEffect } from "react";
import { Button, Checkbox, Select } from "semantic-ui-react";
import { Taccount } from "../api";
import config from "../config";


const SortAmount = ({ up }: { up: boolean }) => (
    <div style={{ display: 'inline-block', width: '9px', height: '8px', marginLeft: '3px' }}>
        <div style={{
            backgroundColor: '#606060', width: (up ? '8px' : '4px'), height: '2px',
            position: 'absolute', marginTop: '6px'
        }}></div>
        <div style={{
            backgroundColor: '#606060', width: '6px', height: '2px',
            position: 'absolute', marginTop: '3px'
        }}></div>
        <div style={{
            backgroundColor: '#606060', width: (up ? '4px' : '8px'), height: '2px',
            position: 'absolute'
        }}></div>
    </div>
)

type TuseState<T> = React.Dispatch<React.SetStateAction<T>>

const SortButton = ({ type, sortAccounts, index, setLastClickedBtnIndex, lastClickedBtnIndex }:
    {
        type: string, sortAccounts: Function, index: number,
        setLastClickedBtnIndex: TuseState<{ index: number, times: number }>
        lastClickedBtnIndex: { index: number, times: number }
    }) => {

    const handleClick = () => {

        setLastClickedBtnIndex(lastV => {
            if (lastV.index !== index) {
                sortAccounts(type, 1)
                return { index, times: 1 }
            }
            else
                sortAccounts(type, lastV.times + 1)
            return { index, times: lastV.times + 1 }
        })
    }
    const shouldDisplayIcon = lastClickedBtnIndex.index === index

    return (
        <Button
            icon
            style={{ margin: '6px' }}
            onClick={handleClick} >
            {type}
            {shouldDisplayIcon ? (
                <SortAmount up={lastClickedBtnIndex.times % 2 === 0} />
            ) : null}
        </Button>
    )
}

export const SortAccounts = (
    { accounts, setAccounts }: { accounts: Taccount[], setAccounts: TuseState<Taccount[]> }
) => {
    const [lastClickedBtnIndex, setLastClickedBtnIndex] = React
        .useState<{ index: number, times: number }>({ index: -1, times: 0 })

    const possibleButtonValues = ['rating', 'behavior', 'tokens', 'ammountOfLpOnAccount', 'costPerGame', 'trophyLevel']
    const sortAccounts = (
        by: 'rating' | 'behavior' | 'tokens' | 'ammountOfLpOnAccount' | 'costPerGame' | 'trophyLevel',
        timesClicked: number
    ) => {
        setAccounts((accounts: Taccount[]) => {
            const sortByIncreasing = () => accounts.sort((a, b) => a[by] - b[by])
            const sortByDecreasing = () => accounts.sort((a, b) => b[by] - a[by])

            timesClicked % 2 === 0 ? sortByIncreasing() : sortByDecreasing()
            const newAccounts = [...accounts]
            return newAccounts
        })
    }

    return (
        <div className='internal-block'>
            <div className="major-header">Сортировать:</div>
            {/* <div style={{ display: 'flex', alignItems: 'center' }}> */}
            {possibleButtonValues.map((value, index) => (
                <SortButton
                    lastClickedBtnIndex={lastClickedBtnIndex}
                    key={index + 'sortButton'}
                    type={value} index={index}
                    sortAccounts={sortAccounts}
                    setLastClickedBtnIndex={setLastClickedBtnIndex}
                />
            ))}
            <Select
                defaultValue='all'
                options={[
                    { key: 'all', value: 'all', text: 'Все' },
                    { key: 'availableForRent', value: 'available-for-rent', text: 'Доступные для аренды' },
                    { key: 'moderation', value: 'moderation', text: 'На модерации' },
                    { key: 'rented', value: 'rented', text: 'Арендованные' },
                    { key: 'temporary-retrieved', value: 'temporary-retrieved', text: 'Временно восстановленные' },
                    { key: 'banned', value: 'banned', text: 'Забаненные' },
                ]}
                onChange={(e, { value: filter }) => {
                    setAccounts(accounts => accounts.map(acc => {
                        if (filter === 'all')
                            if (acc.status === 'banned' || acc.status === 'temporary-retrieved')
                                acc.notVisible = true
                            else
                                acc.notVisible = false
                        else if (filter === 'available-for-rent' && acc.status === 'available-for-rent')
                            acc.notVisible = false
                        else if (filter === 'moderation' && acc.status === 'moderation')
                            acc.notVisible = false
                        else if (filter === 'rented' && acc.status === 'rented')
                            acc.notVisible = false
                        else if (filter === 'temporary-retrieved' && acc.status === 'temporary-retrieved')
                            acc.notVisible = false
                        else if (filter === 'banned' && acc.status === 'banned')
                            acc.notVisible = false
                        else
                            acc.notVisible = true
                        return acc
                    }))
                }} />
        </div>
    )
}

export default SortAccounts