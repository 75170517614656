import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import OrdersFromMmr from "./OrdersFromMmr";
import { SwitchButton } from "../Header";
import OrdersFromDays from "./OrdersFromDays";
import AccountsFromMmr from "./AccountsFromMmr";



type _Tprops = {
    page: 'orders-days' | 'profit-mmr' | 'accounts-mmr'
}
const Stats = ({ page }: _Tprops) => {

    let currentPage
    if (page === 'accounts-mmr')
        currentPage = <AccountsFromMmr />
    else if (page === 'orders-days')
        currentPage = <OrdersFromDays />
    else if (page === 'profit-mmr')
        currentPage = <OrdersFromMmr />


    return (<>
        <div className="standard-divider"></div>
        <div className='big-block-desktop '>
            <div className='internal-block'>
                <SwitchButton text="Прибыль по ммр" link='/admin/stats/profit-mmr' />
                <SwitchButton text="Заказы по дням" link='/admin/stats/orders-days' />
                <SwitchButton text="Аккаунты по ммр" link='/admin/stats/accounts-mmr' />
            </div>
        </div>
        <div className="standard-divider"></div>

        {currentPage}
    </>)
}

export default Stats
