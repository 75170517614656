import React, { useState, useEffect, useRef, useCallback } from "react";
import * as api from '../../api'
import { Button, Card, Input, Popup, Modal, TextArea } from "semantic-ui-react";
import toast from "react-hot-toast";


const EditNotes = ({ accountId }) => {
    const [notes, setNotes] = useState<api.Tnote[]>([])
    const [opened, setOpened] = useState(false)
    const [newNoteText, setNewNoteText] = useState('')

    const wrapper: React.CSSProperties = {
        minHeight: '60vh',
    }

    const footerStyle: React.CSSProperties = {
        position: 'absolute',
        bottom: '0',
        width: '100%',
        backgroundColor: 'rgb(245,245,245)',
        padding: '14px 40px',
    }

    const setNewNotes = () => {
        if (!opened) return
        
        api.getNotes(accountId)
            .then(res => {
                console.log('got notes for account', accountId, res)
                setNotes(res)
            })
            .catch(err => toast.error('Ошибка получения заметок:', err.message))
    }

    const createNewNote = () => {
        api.addNote(newNoteText, accountId)
            .then(res => {
                console.log('created note for account', accountId, res)
                setNewNoteText('')
                setNewNotes()
            })
            .catch(err => toast.error('Ошибка создания заметки:', err.message))
    }

    useEffect(setNewNotes, [opened])

    return (
        <Modal
            onOpen={() => setOpened(true)}
            onClose={() => setOpened(false)}
            open={opened}
            style={wrapper}
            trigger={<Button size="mini" color='olive'>Заметки</Button>}
        >
            <Modal.Header>Редактирование заметок</Modal.Header>
            <Card.Group style={{
                width: '86%', margin: '16px auto 66px auto', maxHeight: '70vh', overflowY: 'scroll',
            }}>
                {notes.map((note, idx) => <Note setNewNotes={setNewNotes} note={note} key={note.accountId + 'note' + idx} />)}
            </Card.Group>

            <div style={footerStyle}>
                <Input fluid placeholder='Текст заметки'
                    value={newNoteText} onChange={e => setNewNoteText(e.target.value)}
                    action={<Button onClick={createNewNote} color="teal" content='Создать заметку' />}
                />
            </div>
        </Modal>
    )
}

type noteProps = {
    note: api.Tnote,
    setNewNotes: () => void
}
const Note = ({ note, setNewNotes }: noteProps) => {
    const [isOpenedEditMenu, setOpenEditMenu] = useState(false)
    const [editText, setEditText] = useState(note.text)
    const [textHeightPx, setTextHeightPx] = useState(0)
    const textRef = useRef<HTMLDivElement | null>(null)

    const noteWrapper: React.CSSProperties = {
        margin: '32px',
        width: '30%',
    }

    useEffect(() => {
        setTextHeightPx((textRef && textRef.current) ? textRef.current.offsetHeight : 0)
    }, [textRef])

    const setNewText = () =>
        api.editNote(note._id, editText)
            .catch(err => toast.error('Ошибка редактирования заметки:', err.message))
            .then(res => {
                toast.success('Заметка успешно отредактирована')
                setNewNotes()
                console.log('edited note', note._id, res)
                setOpenEditMenu(false)
            })

    const deleteNote = () =>
        api.deleteNote(note._id)
            .catch(err => toast.error('Ошибка удаления заметки:', err.message))
            .then(res => {
                toast.success('Заметка успешно удалена')
                setNewNotes()
                console.log('deleted note', note._id, res)
            })

    const popupContent = `Created: ${note.createdAt} \n \t Updated: ${note.updatedAt}`;


    if (!isOpenedEditMenu)
        return <Popup content={popupContent} trigger={
            <Card stye={noteWrapper}>
                <Card.Content>
                    <div ref={textRef}><Card.Description content={note.text} /></div>

                </Card.Content>

                <Card.Content extra>
                    <div className='ui two buttons'>
                        <Button basic color='green' onClick={() => setOpenEditMenu(true)}>
                            Редактировать
                        </Button>
                        <Button basic color='red' onClick={deleteNote}>
                            Удалить
                        </Button>
                    </div>
                </Card.Content>
            </Card>
        } />
    else
        return <Card
            stye={noteWrapper}
        >
            <Card.Content style={{ paddingBottom: '0px' }}>
                <TextArea style={{ width: '100%', maxWidth: '100%', minWidth: '100%', height: textHeightPx + 22 + 'px' }}
                    onChange={(e) => setEditText(e.target.value)} value={editText} />
            </Card.Content>
            <Card.Content extra>
                <div className='ui two buttons'>
                    <Button basic color='green' onClick={setNewText}>
                        Сохранить
                    </Button>
                    <Button onClick={() => {
                        setOpenEditMenu(false)
                        setEditText(note.text)
                    }} basic color='red'>
                        Отменить
                    </Button>
                </div>
            </Card.Content>
        </Card>
}

export default EditNotes