import React, { useState } from "react";
import { Grid, Segment, Button, Input, Checkbox } from 'semantic-ui-react'
import config from '../../config'
import * as api from '../../api'
import './style.css'
import { makeScreenshots } from "../../utils";
import toast from "react-hot-toast";


const Account = ({ path }: { path: string }) => {
    const [mmr, setMmr] = useState('')
    const [confidence, setConfidence] = useState('')
    const [trophylvl, setTrophylvl] = useState('')
    const [behavior, setBehavior] = useState('')
    const [tokens, setTokens] = useState('')

    const [lpAmount, setLpAmount] = useState('')
    const [banned, setBanned] = useState(false)

    const [screenshotType, setType] = useState('rating')

    if (path.split("_").length < 2) {
        throw new Error("Invalid path provided: " + path)
    }

    const _id = path.split('_')[0]
    const remoteImg = config.url + '/unchecked-accounts/' + path + `/${screenshotType}.jpg`

    React.useEffect(() => {
        const element = document.getElementById(path)
        if (element) {
            const zoomEvent = e => zoom(e)

            element.addEventListener('mousemove', zoomEvent)

            return () => element.removeEventListener('mousemove', zoomEvent)
        } else {
            toast.error('Send error from console to dev team')
            console.log('Element is undefiend:', element, 'path:', path)
        }
    }, [path])

    const accountDataForApi: api.TsendNewAccountData = {
        mmr: +mmr, confidence: +confidence, trophylvl: +trophylvl, behavior: +behavior, tokens: +tokens, lpAmount: +lpAmount,
        banned, path, _id
    }

    const sendNewData = () => {
        const request = api.sendNewAccountData(accountDataForApi)

        toast.promise(request, {
            loading: 'Отправка запроса...',
            success: 'Данные успешно отправлены',
            error: 'Ошибка при отправке данных'
        })
    }

    return (
        <Segment color="blue" style={{
            margin: '1.4em', width: '84%',
            display: 'block', marginLeft: 'auto', marginRight: 'auto'
        }}>
            <Grid>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <div>
                            <figure className="zoom" id={path}
                                style={{ backgroundImage: `url(${remoteImg})` }}>
                                <img src={remoteImg} alt="No content" />
                            </figure>
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={5}>
                    <Grid.Column textAlign="center">
                        <Button
                            onClick={() => setType('rating')}
                            content={'Rating + Last Games'}
                        />
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                        <Button
                            onClick={() => setType('tokens')}
                            content={'Tokens'}
                        />
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                        <Button
                            onClick={() => setType('profile_scr')}
                            content={'Behavior + Profile'}
                        />
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                        <Button
                            onClick={() => setType('trophies')}
                            content={'Trophy lvl'}
                        />
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                        <Button
                            onClick={() => setType('start_match_scr')}
                            content={'game searching'}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={5}>
                    <Grid.Column textAlign="center">
                        <Input
                            size='small'
                            value={mmr}
                            onChange={(e) => setMmr(e.target.value)}
                            placeholder={'Rating'}
                        />
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                        <Input
                            size='small'
                            value={confidence}
                            onChange={(e) => setConfidence(e.target.value)}
                            placeholder={'Confidence'}
                        />
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                        <Input
                            size='small'
                            value={tokens}
                            onChange={(e) => setTokens(e.target.value)}
                            placeholder={'Tokens'}
                        />
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                        <Input
                            size='small'
                            value={behavior}
                            onChange={(e) => {
                                setBehavior(e.target.value)
                            }}
                            placeholder={'Behavior'}
                        />
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                        <Input
                            size="small"
                            value={trophylvl}
                            onChange={(e) => {
                                setTrophylvl(e.target.value)
                            }}
                            placeholder={'Level'}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={4}>
                    <Grid.Column textAlign="center">
                        <Input
                            value={lpAmount}
                            onChange={(e) => setLpAmount(e.target.value)}
                            placeholder={'Lp amount'}
                        />
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                        <Checkbox
                            checked={banned}
                            onChange={(e, w) => setBanned(w.checked as boolean)}
                            label={'Banned'}
                        />
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                        <Button
                            onClick={sendNewData}
                            content={'Send data'}
                        />
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                        <Button content='make new scrs'
                            onClick={() => makeScreenshots(_id, path, true)}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    )
}

function zoom(e) {
    var zoomer = e.currentTarget;
    let offsetX, offsetY, x, y
    e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX
    e.offsetY ? offsetY = e.offsetY : offsetX = e.touches[0].pageX
    x = offsetX / zoomer.offsetWidth * 100
    y = offsetY / zoomer.offsetHeight * 100
    zoomer.style.backgroundPosition = x + '% ' + y + '%';
}

export default Account
