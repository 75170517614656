const config = {
    // url: 'http://localhost:4000/api/v1',
    url: 'https://rentdota2.com/api/v1',
    displayBannedAccsByDefault: false,

    minMmr: 0,
    maxMmr: 5000,

    minTokens: 6,
    minBehavior: 8000,
    closeBehavior: 7000,
}

export default config
