import axios from 'axios'
import React, { useEffect } from 'react'
import JsonFormatter from 'react-json-formatter'
import { Button, Input, Modal, Select } from 'semantic-ui-react'
import './AccountLogs.css'

interface ILog {
    timestamp: number
    level: 0 | 1 | 2 | 3 | 4
    type: string
    payload: string
}
function logLevelToString(level: 0 | 1 | 2 | 3 | 4): string {
    switch (level) {
        case 0: return 'debug'
        case 1: return 'info'
        case 2: return 'warn'
        case 3: return 'error'
        case 4: return 'fatal'
        default: return 'unknown'
    }
}

const backendApi = axios.create({
    baseURL: 'https://31.211.77.11:4411',
    headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
    },
})

async function fetchLogsFromApi(accountId: string, page: number, pageSize: number)
    : Promise<ILog[]> {
    const res = await backendApi.get<{
        page: number
        pageSize: number
        data: ILog[]
    }>('/api/v1/accounts/logs', {
        params: { page, pageSize, accountId },
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    })
    return res.data.data
}

export function AccountLogs(params: {
    accountId: string
}) {
    const [opened, setOpened] = React.useState(false)
    const [rawLogs, setRawLogs] = React.useState<ILog[]>([])
    const [minLogLevel, setMinLogLevel] = React.useState<0 | 1 | 2 | 3 | 4>(0)

    const wrapper: React.CSSProperties = {
        padding: '42px 18px',
        fontSize: '20px'
    }

    const jsonStyle = {
        booleanStyle: { color: 'dodgerblue' },
        braceStyle: { color: '#646464' },
        bracketStyle: { color: '#646464' },
        commaStyle: { color: '#b0b0b0' },
        falseStyle: { color: 'red' },
        nullStyle: { color: 'magenta' },
        numberStyle: { color: 'darkorange' },
        propertyStyle: { color: '#9CDCFE' }, // Like VS Code
        stringStyle: { color: '#CE9178' }, // Like VS Code
        trueStyle: { color: 'limegreen' }
    }

    const fetchLogs = (page = 1, pageSize = 50) =>
        fetchLogsFromApi(params.accountId, page, pageSize)
            .then(res => {
                setRawLogs(res)
            })

    return (<Modal open={opened} onOpen={_ => setOpened(true)} onClose={_ => setOpened(false)}
        trigger={< Button icon='list' circular color='grey' />}
        style={{ width: 'calc(60% + 300px)' }}
    >
        <div style={wrapper}>
            <Filter
                fetchLogs={fetchLogs}
                setMinLogLevel={setMinLogLevel}
            />
            <div className="logs-monitor">
                <div className="log-container">
                    {rawLogs.length === 0 && <div>No logs</div>}
                    {rawLogs.map((raw, index) => {
                        if (raw.level < minLogLevel) return null
                        const payload = raw.payload as any
                        // Format DD/MM/YY HH:MM:SS
                        const time = new Date(raw.timestamp).toLocaleString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                        })
                        const lvlstr = logLevelToString(raw.level)
                        const json = Array.isArray(payload) ?
                            payload.map((item, index) => {
                                return <JsonFormatter key={index} json={item} tabWith={4} jsonStyle={jsonStyle} />
                            })
                            :
                            <JsonFormatter key={index} json={payload} tabWith={4} jsonStyle={jsonStyle} />

                        return (
                            <div key={index} className={'log'} >
                                {time}
                                <span className={`log-${lvlstr}-prefix`} >
                                    &nbsp;&nbsp;[{lvlstr}]
                                </span>
                                &nbsp;&nbsp;{raw.type}
                                <div className='log-payload'>{json}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    </Modal >
    )
}

function Filter({ fetchLogs, setMinLogLevel }:
    {
        fetchLogs: (page?: number, pageSize?: number) => Promise<any>,
        setMinLogLevel: React.Dispatch<React.SetStateAction<0 | 1 | 2 | 3 | 4>>
    }
) {
    const [page, setPage] = React.useState<string>('1')
    const [pageSize, setPageSize] = React.useState<string>('50')

    const [loading, setLoading] = React.useState<boolean>(false)

    function handleRefresh() {
        if (Number.isNaN(+page) || Number.isNaN(+pageSize)) return
        setLoading(true)
        fetchLogs(+page, +pageSize)
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        handleRefresh()
    }, [])

    return (
        <div id="header">
            <Button size='big' onClick={handleRefresh}>Refresh</Button>

            <Input
                type="text"
                value={page}
                onChange={(e) => setPage(e.target.value)}
                placeholder="Page"
            />
            <Input
                type="text"
                value={pageSize}
                onChange={(e) => setPageSize(e.target.value)}
                placeholder="Page Size"
            />

            {loading && <span>Loading...</span>}

            <Select
                style={{ marginLeft: '10px' }}
                defaultValue={0}
                options={[
                    { key: 'debug', value: 0, text: 'debug' },
                    { key: 'info', value: 1, text: 'info' },
                    { key: 'warn', value: 2, text: 'warn' },
                    { key: 'error', value: 3, text: 'error' },
                    { key: 'fatal', value: 4, text: 'fatal' },
                ]}
                onChange={(_, { value }) => setMinLogLevel(Number(value) as 0 | 1 | 2 | 3 | 4)}
            />
        </div >
    )
}
